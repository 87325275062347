html, body, #__next {
  height: 100% !important;
  width: 100% !important;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}

.btn-dark-to-light-green-gradient { 
  background: rgb(0,76,66);
  background: linear-gradient(90deg, rgba(0,76,66,1) 19%, rgba(64,205,180,1) 100%);
}

.bg-yellow-green {
  background: rgb(181,172,73);
  background: linear-gradient(90deg, rgba(181,172,73,0.7679446778711485) 0%, rgba(27,159,136,0.8015581232492998) 100%);
}

.bg-green-yellow {
  background: rgb(181,172,73);
  background: linear-gradient(90deg, rgba(27,159,136,0.8015581232492998) 0%, rgba(181,172,73,0.7679446778711485)  100%);
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-number-input input:focus {
  outline: none !important;
}

.custom-number-input button:focus {
  outline: none !important;
}

.text-dark-gradient-gereen {
  background: linear-gradient(to right, #004c42 0%, #1b9f88 100%);
}

.swiper-button-prev {
  background-image: url("../images/icon/prev-swiper.svg") !important;
  background-size: 35px;
  background-repeat: no-repeat;
  width: 35px !important;
  left: -1px;
  &::after {
    content: "" !important;
  }
}

.swiper-button-next {
  background-image:url("../images/icon/next-swiper.svg")  !important;
  background-size: 35px;
  background-repeat: no-repeat;
  width: 35px !important;
  right: -1px;
  &::after {
    content: "" !important;
  }
}

.bg-yellow-green-tb {
  background: rgb(250,181,56);
  background: linear-gradient(180deg, rgba(250,181,56,0.5) 0%, rgba(27,159,133,0.5) 100%);
}

.h-fill-avail {
  height: -webkit-fill-available;
}

.text-ol {
  ul, ol {
    list-style-type: disc;
  }
}

.customs_mod_boxs{
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  z-index: 1000;
  display: block !important;
}
.customs_mod_boxs.hidden{
  display: none !important;
}

.box_form_modal{
  // max-width: 85%;
  max-height: inherit;
  margin: 2em auto 3rem;
}
.box_form_modal .middles_form{
  margin: 0 auto;
}
.box_form_modal .middles_form .input_group label{
  width: auto;
  font-size: 14px;
}
.box_form_modal .middles_form .input_group input{
  width: 100%;
  font-size: 13px;
}


.box_form_modal{
  .top_popup{

  }
  .middles_form{
      .input_group{
          label{
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 20px;
              letter-spacing: -0.006em;
              color: #333333;
          }
          select.form-control,
          input.form-control{
              
          }
          .box_frame_input{}
          .box_frame_file{
              h6{
                  font-style: normal;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 22px;
                  color: #999999;
              }
              .info_alerts{
                  p{
                      font-style: normal;
                      font-weight: 500;
                      font-size: 12px;
                      line-height: 22px;
                      color: #999999;
                      span.red{
                          color: #FF0000;
                      }
                  }
              }
          }
          clear: both;
      }
      .lefts_form{
          
      }
      .rights_form{

      }
  }
  button.bt_cancel_form{
      background: #F5F6F8;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
      border-radius: 6px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      letter-spacing: -0.006em;
      color: #05433B;
      width: 230px;
  }
  button.bt_submit_form{
      background: #05433B;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
      border-radius: 6px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      letter-spacing: -0.006em;
      color: #FFFFFF;
      width: 230px;
  }
  clear: both;
}

.drop-zone {
max-width: 100%;
min-height: 220px;
height: auto;
padding: 25px;
display: flex;
align-items: center;
justify-content: center;
text-align: center;
font-family: "Quicksand", sans-serif;
font-weight: 500;
font-size: 20px;
position: relative;
cursor: pointer;
color: #cccccc;
border: 1px dashed rgba(108, 128, 154, 0.5);
border-radius: 5px;
span{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #666666;
}
span.set_or{
    font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #666666;
}
span.choose_file{
    font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.2px;
      text-decoration-line: underline;
      color: #05433B;
}
}

.drop-zone--over {
border-style: solid;
}

.drop-zone__input {
opacity: 0;
cursor: pointer;
}

.drop-zone__thumb {
width: 100%;
height: 100%;
border-radius: 10px;
overflow: hidden;
background-color: #f5f5f5;


  position: absolute;
  z-index: 2;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.drop-zone__thumb::after {
content: attr(data-label);
position: absolute;
bottom: 0;
left: 0;
width: 100%;
padding: 5px 0;
color: #ffffff;
background: rgba(0, 0, 0, 0.75);
font-size: 14px;
text-align: center;
}

.top_popup{
  .closes_btns{
      button{
              background: transparent !important;
              position: absolute;
              top: 10px;
              right: 10px;
              z-index: 15;
              border: none;
              box-shadow: none;
              padding: 0px;
              width: auto;
          .iconify{
              font-size: 22px;
          }
      }
  }
}

.error_icon{
  font-size: 70px;
  color: red;
  display: block;
  margin: 0 auto;
}

.blocks_loader_spin{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.4);
  z-index: 99999
}

.h-fill-avail {
  height: -webkit-fill-available; /* Pengaturan tinggi untuk browser WebKit seperti Chrome dan Safari */

  @media only screen and (max-width: 600px) {
    height: auto; /* Mengatur tinggi menjadi otomatis untuk layar ponsel */
  }
}